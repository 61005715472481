.react-player > video {
  border-radius: 4px;
}

.react-player {
  display: flex;
}

.MuiLinearProgress-bar {
  transition: none !important;
}

.MuiLinearProgress-dashed {
  animation: none !important;
  background-image: none !important;
  background-color: #9E9E9E;
  opacity: 0.5;
}