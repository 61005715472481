.ql-container{
  width: 100% !important;
  height: auto !important;
}
.ql-editor{
  width: 100% !important;
  height: auto !important;
}
.ql-editor > ul {
  padding-left: 0;
}
.ql-toolbar{
  width: 100% !important;
}
